import React from 'react';

const styles = {
    container: {
        fontSize: '1.2rem',
        lineHeight: 1.5,
        margin: '0 auto',
        maxWidth: 800,
        padding: '0 1rem',
    },
    header: {
        textAlign: 'center',
    },
    summary: {
        fontWeight: 'bold',
        marginBottom: '1rem',
    },
};

const PrivacyPolicy = () => {
    return (
        <div style={styles.container}>

            <h1 style={styles.header}>Privacy Policy</h1>
            <div>
                <p>
                    This Privacy Policy describes how AnswerSolutions LLC ("we," "us," or "our") collects, uses, and shares information about you when you use our mobile application and website (collectively, the "Services"). By accessing or using our Services, you agree to be bound by this Privacy Policy. If you do not agree to this Privacy Policy, please do not use our Services.
                </p>
                <p style={styles.summary}>
                    Summary: We collect information you provide to us and automatically when you use our Services. We use your information to provide and improve our Services, communicate with you, and comply with legal obligations. We may share your information with third-party service providers and in response to legal requests.
                </p>
                <p>
                    Information We Collect: We may collect information that you provide to us directly, such as when you register for an account or submit a request through our customer support channels. The types of information we may collect include your name, email address, and other contact information.
                </p>
                <p>
                    We may also collect information automatically when you use our Services, such as your IP address, device type, operating system, and other technical information about your use of the Services.
                </p>
                <p>
                    How We Use Your Information: We may use the information we collect to provide and improve our Services, respond to your requests and inquiries, communicate with you about our Services, analyze usage trends and improve the Services, and enforce our legal rights and obligations.
                </p>
                <p>
                    We may share your information with third-party service providers who perform services on our behalf, such as payment processing, data analysis, and customer support. We may also share your information in response to a legal request or to comply with applicable laws and regulations.
                </p>
                <p>
                    Third-Party Services: Our Services may use third-party service providers such as OpenAI, and technology provided by Google and Apple. We also use cloud infrastructure provided by Amazon Web Services (AWS) and communication infrastructure provided by mobile providers to access the internet on devices and computers. We are not responsible for any downtime, latency, or other issues that may arise from the use of these third-party services.
                </p>
                <p>
                    Data Retention and Security: We retain your information for as long as necessary to provide our Services and as otherwise necessary to comply with our legal obligations, resolve disputes, and enforce our agreements. We take reasonable measures to protect your information from unauthorized access, use, or disclosure, such as encryption and access controls. However, no method of transmission over the internet or method of electronic storage is completely secure, so we cannot guarantee the absolute security of your information.
                </p>
                <p>
                    Children's Privacy: Our Services are not directed to children under the age of 13. If you are a parent or guardian and believe that your child has provided us with personal information without your consent, please contact us at [contact information] to request deletion of that information.
                </p>
                <p>
                    Changes to This Policy: We may update this Privacy Policy from time to time. If we make material changes to this Privacy Policy, we will notify you by email or by posting a notice on our website prior to the effective date of the changes. Your continued use of our Services after the effective date of the changes constitutes your acceptance of the revised Privacy Policy.
                </p>
                <p>
                    Contact Us: If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at support@answersolutions.net.
                </p>
            </div>
        </div>
    );
};

export default PrivacyPolicy;