import React from 'react';
import {Box, Divider, Typography} from '@mui/material';

const SpacingBox = () => (
    <Box height={24} /> // Adjust the height value to control the space between Boxes
);

const Support = () => {
    const faq = [
        {
            question: 'Q: How can I contact customer support?',
            answer: 'A: You can contact us via email at support@answersolutions.net.'
        },
        {
            question: 'Q: What languages do WiseTalk and Speech Translator support?',
            answer: 'A: The apps can recognize and synthesize any language supported by your device.'
        },
        {
            question: 'Q: Is WiseTalk available on all mobile devices?',
            answer: 'A: WiseTalk is available for download on modern iOS and Android devices.'
        },
        {
            question: 'Q: How do I purchase additional tokens?',
            answer: 'A: You can purchase additional tokens through the app. Go to the Account Settings, and tap the \'Add Credit\' button. The price varies depending on the tier you choose: $1 for 40K, $3 for 200K, and $5 for 500K. You can also subscribe to the service to double the number of tokens for the same price, gain access to the history of your conversations and more.'
        },
        {
            question: 'Q: Is my data safe with WiseTalk?',
            answer: 'A: WiseTalk takes your privacy and security seriously. We use all necessary standard tools and practices to protect your data from unauthorized access, use, or disclosure. We also have a comprehensive privacy policy that outlines our data practices.'
        },
        {
            question: 'Q: What kind of AI assistant does WiseTalk use?',
            answer: 'A: WiseTalk uses ChatGPT like LLM via the official OpenAI API.'
        },
        {
            question: 'Q: Can WiseTalk work offline?',
            answer: 'A: WiseTalk processes speech recognition and synthesis directly on the device, so it works even in places with poor internet connectivity. However, it requires an internet connection to send request to the AI model and to receive the responses.'
        },
        {
            question: 'Q: What is the free trial?',
            answer: 'A: WiseTalk offers a free trial of 10K (10,000) tokens, which you can use to test out the app\'s features.'
        },
        {
            question: 'Q: How long can I chat with the AI using 10,000 tokens, is this a lot?',
            answer: 'A: The amount of time you can chat with the AI using 10,000 tokens will depend on various factors, such as the length and complexity of the conversations and the specific language processing tasks being performed. As a rough estimate, 10,000 tokens could enable you to have several hours of continuous conversations with the AI, but the exact duration will vary depending on the specific use case. For example, a standard novel generally contains between 60,000 to 100,000 words, so 10,000 words is around one tenth the length of a novel. Alternatively, if we consider an average conversation between two people, it is estimated that an hour-long conversation typically contains around 7,000 to 10,000 words. Therefore, 10,000 word tokens would be equivalent to roughly 1 to 3 hours of conversation.'
        },
        {
            question: 'Q: What if I have a suggestion or feedback for WiseTalk?',
            answer: 'A: We welcome any suggestions or feedback you may have. You can send them to us via email at feedback@answersolutions.net.'
        },
    ];

    return (
        <Box sx={{display: 'flex', flexDirection: 'column'}}>
            <SpacingBox />
            <SpacingBox />
            <Typography variant="h4" mb={2}>FAQ And Support</Typography>
            {faq.map((item, index) => (
                <Box key={index} sx={{mb: 2}}>
                    <Typography variant="h6" mb={1}>{item.question}</Typography>
                    <Typography variant="body1">{item.answer}</Typography>
                    <Divider sx={{my: 2}}/>
                </Box>
            ))}
            <SpacingBox />
            <Typography variant="h4" mb={2}>Need Help?</Typography>
            <Typography variant="body1" mb={4}>Contact us via email at support @ answersolutions . net for
                assistance.</Typography>
        </Box>
    );
};

export default Support;
