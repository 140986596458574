import React from 'react';

const styles = {
    container: {
        fontSize: '1.2rem',
        lineHeight: 1.5,
        margin: '0 auto',
        maxWidth: 800,
        padding: '0 1rem',
    },
    header: {
        textAlign: 'center',
    },
    summary: {
        fontWeight: 'bold',
        marginBottom: '1rem',
    },
};

const UserAgreement = () => {
    return (
        <div style={styles.container}>
            <h1 style={styles.header}>Terms of Use</h1>
            <p style={styles.summary}>
                By using our Services, you agree to follow these terms of use. You must use our Services only for lawful purposes, and you are responsible for all content that you submit. Fees may apply for some services, and we may terminate your access to our Services if you breach these terms.
            </p>
            <h2>User Conduct</h2>
            <p>
                You agree to use our Services only for lawful purposes and in accordance with these Terms. You are responsible for all content that you submit, post, or otherwise make available through our Services, and you represent and warrant that such content is accurate, not confidential, and not in violation of any laws or third-party rights. You must be at least 13 years old to use the Services. If you are under 18, you must have your parent or legal guardian's permission to use the Services. If you use the Services on behalf of another person or entity, you must have the authority to accept the Terms on their behalf. You must provide accurate and complete information to register for an account. You may not make your access credentials or account available to others outside your organization, and you are responsible for all activities that occur using your credentials.
            </p>
            <h2>Fees and Payments</h2>
            <p>
                (a) Fees and Billing. You will pay all fees charged to your account ("Fees") according to the prices and terms on the applicable pricing page, or as otherwise agreed between us in writing. We have the right to correct pricing errors or mistakes even if we have already issued an invoice or received payment. You will provide complete and accurate billing information, including a valid and authorized payment method. We will charge your payment method on an agreed-upon periodic basis but may reasonably change the date on which the charge is posted. You authorize AnswerSolutions LLC and its affiliates, and our third-party payment processor(s), to charge your payment method for the Fees. If your payment cannot be completed, we will provide you with written notice and may suspend access to the Services until payment is received. Fees are payable in U.S. dollars and are due upon invoice issuance. Payments are nonrefundable except as provided in this Agreement.
            </p>
            <h2>Free Tier</h2>
            <p>
                You may not create more than one account to benefit from credits provided in the free tier of the Services. If we believe you are not using the free tier in good faith, we may charge you standard fees or stop providing access to the Services.
            </p>
            <h2>Intellectual Property</h2>
            <p>
                All content and materials available through our Services, including the Talkwise name, logo, and AI assistant, are the property of AnswerSolutions LLC or our licensors, and are protected by copyright, trademark, and other intellectual property laws. You agree not to reproduce, distribute, modify, or create derivative works of any content or materials from our Services
            </p>
            <h2>Disclaimer of Warranties</h2>
            <p>
                Our Services and all content and materials available through our Services are provided on an "as is" and "as available" basis, without warranties of any kind, either express or implied, including without limitation implied warranties of merchantability, fitness for a particular purpose, or non-infringement. We do not warrant that our Services will be uninterrupted, error-free, or free of viruses or other harmful components. AI may occasionally generate incorrect information, harmful instructions, or biased content, and has limited knowledge of the world and events after 2021.
            </p>
            <h2>Third-Party Services</h2>
            <p>
                Our Services may use third-party service providers such as OpenAI, and technology provided by Google and Apple. We also use cloud infrastructure provided by Amazon Web Services (AWS) and communication infrastructure provided by mobile providers to access the internet on devices and computers. We are not responsible for any downtime, latency, or other issues that may arise from the use of these third-party services.
            </p>
            <h2>Limitation of Liability</h2>
            <p>
                In no event shall AnswerSolutions LLC or its affiliates, officers, directors, employees, or agents be liable for any direct, indirect, incidental, special, or consequential damages arising out of or in any way connected with your use or inability to use our Services, or for any content or materials obtained through our Services. This limitation of liability applies regardless of the form of action, whether in contract, tort, strict liability, or otherwise.
            </p>
            <h2>Modification of Terms</h2>
            <p>
                We may modify these Terms at any time by posting revised Terms on our website or mobile application. Your continued use of our Services after such modifications constitutes your acceptance of the revised Terms.
            </p>
            <h2>Termination</h2>
            <p>
                We reserve the right to terminate or suspend your access to our Services at any time, without notice and for any reason, including without limitation if you breach these Terms.
            </p>
            <h2>Governing Law</h2>
            <p>
                These Terms and your use of our Services are governed by the laws of [state/country], without regard to its conflict of law provisions.
            </p>
            <h2>Contact Us</h2>
            <p>
                If you have any questions or concerns about these Terms, please contact us at support@answersolutions.net.
            </p>
        </div>
    );
};

export default UserAgreement;