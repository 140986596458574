import './App.css';
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { CssBaseline, Container, ThemeProvider, createTheme } from '@mui/material';

import Navigation from './components/Navigation/Navigation';
import Footer from './components/Footer/Footer';
import Home from './pages/Home/Home';
// import Pricing from './pages/Pricing/Pricing';
import Tutorial from './pages/Tutorial/Tutorial';
import PrivacyPolicy from './pages/PrivacyPolicy/PrivacyPolicy';
import UserAgreement from './pages/UserAgreement/UserAgreement';
import Support from './pages/Support/Support';
// import LockScreen from './pages/LockScreen/LockScreen';

const theme = createTheme({
  palette: {
    primary: {
      main: '#2196F3',
    },
    secondary: {
      main: '#FF3D00',
    },
    background: {
      default: '#FFFFFF',
    },
    grayBackground: {
      default: '#D7D7D7',
    }
  },
});

function App() {
  // const [pincode, setPincode] = useState('');
  //
  // const handleUnlock = (enteredPin) => {
  //   if (enteredPin === 'cannotstop') {
  //     setPincode('');
  //   }
  // };

  return (
      <div>
        {/*{pincode === '' || window.location.hash.startsWith('#cannotstop') ? (*/}
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Router>
          <Navigation />
          <Container maxWidth="md" sx={{ display: 'flex', justifyContent: 'center' }}>
            <Routes>
              <Route path="/wisetalk-ai-powered-voice-activated-assistant-and-chat-gpt-hands-free" element={<Home />} />
              {/*<Route path="/pricing" element={<Pricing />} />*/}
              <Route path="/tutorial" element={<Tutorial />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/user-agreement" element={<UserAgreement />} />
              <Route path="/support" element={<Support />} />
              <Route path="/" element={<Home />} />
            </Routes>
          </Container>
          <Footer />
        </Router>
      </ThemeProvider>
        {/*) : (*/}
        {/*    <LockScreen handleUnlock={handleUnlock} />*/}
        {/*)}*/}
      </div>
  );
}

export default App;
