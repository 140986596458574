import React from 'react';
import {Box, Typography, Divider} from '@mui/material';
import ReactPlayer from "react-player"

const SpacingBox = () => (
    <Box height={24}/> // Adjust the height value to control the space between Boxes
);

const Tutorial = () => {
    return (
        <Box sx={{
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center'
        }} width="100%">
            <SpacingBox/>
            <Typography variant="h4" component="h2" color="primary.main" align="center" gutterBottom>
                <b>WiseTalk</b> and <b>Speech Translator</b> User Guide and Tutorial
            </Typography>
            <SpacingBox/>
            <Box sx={{bgcolor: 'grayBackground.default', p: 4, borderRadius: 4, boxShadow: 4}} width="100%">
                <Typography variant="h6">
                    How does <b>WiseTalk</b> work?
                    <Divider sx={{my: 2}} color="white"/>
                    <ul>
                        <li>Download the WiseTalk app from the Apple App Store or Google Play Store.</li>
                        <li>Launch the WiseTalk app.</li>
                        <li>Tap on the Mic button, and use your voice to start a conversation with the AI assistant.
                        </li>
                        <li>Tap on the Arrow-Up (Send) button.</li>
                        <li>Wait for the response and listen.</li>
                    </ul>
                    Experience hands-free convenience as the app processes your request and reads aloud the text message
                    response provided by the AI Assistant.
                </Typography>
                <Divider sx={{my: 2}} color="white"/>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    p: 1
                }}>
                    <img src="/screenshots.png" width="100%" alt="app logo"/>
                </Box>
                <Divider sx={{my: 2}} color="white"/>
                <Typography variant="h6" gutterBottom>
                    See more details in this article on LinkedIn
                </Typography>
                <a
                    href="https://www.linkedin.com/pulse/wisetalk-app-your-voice-activated-portable-chatgpt/"
                    rel="noopener noreferrer">WiseTalk App: Your Voice-Activated, Portable ChatGPT Sidekick</a>.
            </Box>
            <SpacingBox/>
            <Box sx={{bgcolor: 'grayBackground.default', p: 4, borderRadius: 4, boxShadow: 4}} width="100%">
                <Typography variant="h6" gutterBottom>
                    How does <b>Speech Translator</b> work?
                    <Divider sx={{my: 2}}/>
                    <ul>
                        <li>Download the Speech Translator app from the Apple App Store or Google Play Store.</li>
                        <li>Launch the Speech Translator app.</li>
                        <li>Choose your native language by tapping on button A.</li>
                        <li>Select the foreign language by tapping on button B, on the opposite side of the screen.</li>
                        <li>Tap on the Mic button and use your voice to start a conversation.</li>
                        <li>Tap on the Arrow-Up (Send) button.</li>
                        <li>Wait for the translation and let the person you want to speak with listen to it.</li>
                        <li>Allow them to tap on the Mic button, on the opposite side of the screen, and speak.</li>
                        <li>Let them tap on the Arrow-Up (Send) button.</li>
                        <li>Wait for the translation, listen, and repeat.</li>
                    </ul>
                </Typography>
                <Divider sx={{my: 2}}/>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    p: 1
                }}>
                    <img src="/translator.png" width="100%" alt="app logo"/>
                </Box>
                <Divider sx={{my: 2}} color="white"/>
                <Typography variant="h6" gutterBottom>
                    See more details in this article on LinkedIn
                </Typography>
                <a
                    href="https://www.linkedin.com/pulse/wisetalk-app-travelers-new-advanced-role-voice-translator/"
                    rel="noopener noreferrer">WiseTalk App for Travelers, a new advanced Role: 'Voice Translator' -
                    Breaking
                    Language Barriers Between People!</a>.
            </Box>
            <SpacingBox/>
            <Box sx={{bgcolor: 'grayBackground.default', p: 4, borderRadius: 4, boxShadow: 4}} width="100%">
                <Typography variant="h6" gutterBottom>
                    How does 'Proofreader' work in the App?
                    <Divider sx={{my: 2}}/>
                    <ul>
                        <li>Tap on the Plus button and select 'Proofreader'.</li>
                        <li>On the subsequent screen, tap the Mic button if you wish to use voice dictation for
                            composing text.
                        </li>
                        <li>Press the Send button if your sole intention is to proofread for correct grammar, syntax,
                            punctuation, and other potential errors.
                        </li>
                        <li>Once you receive the result, you can copy it for use in any other application.</li>
                        <li>To maximize the benefits of the Proofreader, activate the Advanced Option by toggling it
                            On.
                        </li>
                        <li>Select the desired <b>text tone</b>, <b>style</b>, and <b>target audience</b> you wish to
                            address.
                        </li>
                        <li>Tap the Send button. Proofreader will not only correct grammar, syntax, and punctuation but
                            also enhance your writing to meet your desired standards.
                        </li>
                        <li>Once you receive the refined text, you can copy it for use in any other application.
                            Additionally, you can review all modifications in the "All Changes Explained" section.
                        </li>
                    </ul>
                </Typography>
                <Divider sx={{my: 2}}/>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    p: 1
                }}>
                    <img src="/screenshots_advanced.png" width="100%" alt="app logo"/>
                </Box>
                <Divider sx={{my: 2}} color="white"/>
                <Typography variant="h6" gutterBottom>
                    How does 'Proofreading Keyboard' work?
                    <Divider sx={{my: 2}}/>
                    <ul>
                        <li>Enable the WiseTalk, AI Proofreading Keyboard in the Settings.</li>
                        <li>For iOS device, navigate to Settings > General > Keyboards > Add New Keyboard. Next, grant
                            'Full Access' to the keyboard, as it sends texts to the cloud/ChatGPT for proofreading.
                        </li>
                        <li>For Android, navigate to Settings > System > Language & Input > On-screen Keyboard. Then,
                            turn on the toggle next to 'WiseTalk Proofreading Keyboard'.
                        </li>
                        <li>That's it! Now, open any app with a text input field, and you'll find the WiseTalk Keyboard
                            available by switching keyboards.
                        </li>
                        <li>Simply type your text and press the 'Proofread' button to correct grammar, syntax, and
                            punctuation. The revised text will automatically appear in the input field. If you want to
                            proofread only a portion of the text, select it before hitting 'Proofread'.
                        </li>
                        <li>To unlock advanced features, such as the ability to select a specific text tone, style, and
                            target audience for your writing, tap on the "Advanced" button located at the top of the
                            keyboard.
                        </li>
                        <li>Choose the desired text tone, style, and audience.</li>
                        <li>Once set, simply hit the 'Proofread' button.</li>
                        <li>Your revised text will appear in the "Result" tab above the "All Changes Explained" section.
                            You can check this if you're curious about the changes. If you like the modifications,
                            simply tap the 'Use It' button at the top of the keyboard.
                        </li>
                    </ul>
                </Typography>
                <Divider sx={{my: 2}}/>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    p: 1
                }}>
                    <img src="/keyboard_three.png" width="100%" alt="app logo"/>
                </Box>

                <Divider sx={{my: 2}} color="white"/>
                <Typography variant="h6" gutterBottom>
                    See more details in this article on LinkedIn
                </Typography>
                <a href="https://www.linkedin.com/pulse/wisetalk-app-writing-introducing-new-role-proofreading"
                   rel="noopener noreferrer">WiseTalk App for Writing: Introducing a New Role and the Proofreading
                    Keyboard – Perfecting Every Word and Sentence You Write!</a>.
            </Box>
            <SpacingBox/>
            <Box sx={{bgcolor: 'grayBackground.default', p: 4, borderRadius: 4, boxShadow: 4}} width="100%">
                <Typography variant="h6">
                    WiseTalk App: Voice Translator Feature in Action at an Italian Restaurant
                    <Divider sx={{my: 2}} color="white"/>
                </Typography>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    p: 1
                }}>
                    <ReactPlayer url="https://youtu.be/fBEi_SoQFOc"/>
                </Box>

            </Box>
            <SpacingBox/>
        </Box>
    );
};

export default Tutorial;