import React from 'react';
import {Box, Typography, Divider} from '@mui/material';

const SpacingBox = () => (
    <Box height={24}/> // Adjust the height value to control the space between Boxes
);

const Home = () => {
    return (
        <Box sx={{
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center'
        }} width="100%">
            <SpacingBox/>
            <SpacingBox/>
            <Typography variant="h5" component="h2" color="primary.main" align="center" gutterBottom>
                Welcome to <b>Speech Translator</b> and <b>Wise Talk</b>, the apps designed to unleash the power of Artificial
                Intelligence for everyone.
            </Typography>
            <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                bgcolor: 'primary.main',
                p: 0,
                borderRadius: '50%',
                boxShadow: 4
            }}>
                <img src="/voicebridge192.png" style={{borderRadius: '50%'}} alt="app logo"/>
            </Box>
            <SpacingBox/>
            <Typography variant="h5" component="h2" color="primary.main" align="center" gutterBottom>
                Speak Anywhere, Be Heard, Understand Everyone!
            </Typography>
            <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                bgcolor: 'primary.main',
                p: 1,
                borderRadius: '50%',
                boxShadow: 4
            }}>
                <img src="/logo192.png" style={{borderRadius: '50%'}} alt="app logo"/>
            </Box>
            <SpacingBox/>
            <Typography variant="h5" component="h2" color="primary.main" align="center" gutterBottom>
                Say, Send, Listen, Marvel, Repeat!
            </Typography>
            <SpacingBox/>
            <SpacingBox/>
            <Box sx={{bgcolor: 'primary.main', p: 4, borderRadius: 4, boxShadow: 4}} width="100%">
                <Typography variant="h6" gutterBottom color="white">
                    Embark on a linguistic adventure with <b>Speech Translator</b>, inspired by "The Hitchhiker's Guide
                    to the
                    Galaxy's" Babel Fish. This universal translator bridges language gaps, improving global
                    communication. Powered by your device's speech-to-text and text-to-speech engines, engage in
                    intuitive, voice-driven conversations. Seamlessly interact with our advanced AI model,
                    breaking down barriers for enhanced understanding worldwide. Experience the future of communication
                    today.
                    <Divider sx={{my: 2}} color="white"/>
                    <Typography variant="h6" gutterBottom>
                        <b>Feature Highlights:</b>
                        <ul>
                            <li>Local Speech Processing: app prioritizes user privacy and convenience, performing
                                speech recognition and synthesis directly on your device—no need to send voice data to
                                the cloud.
                            </li>
                            <li>Reliable Connectivity: Designed for optimal performance in areas with poor internet
                                connections, app exchanges text-only data to ensure a smooth user experience.
                            </li>
                            <li>Multilingual Support: app embraces a global audience with its ability to recognize
                                and synthesize any language supported by device.
                            </li>
                            <li>Token-Based Access: Enjoy an initial 10K tokens for free, and refill your balance
                                with affordable token packages.
                            </li>
                            <li>Easy SignUp: One-Click Apple Registration: Seamless and speedy registration process
                                using your Apple account.
                            </li>
                        </ul>
                    </Typography>
                </Typography>
            </Box>
            <SpacingBox/>
            <Box sx={{bgcolor: 'grayBackground.default', p: 4, borderRadius: 4, boxShadow: 4}} width="100%">
                <Typography variant="h6">
                    <b>WiseTalk</b> is the ultimate voice-activated intelligent assistant that combines the power of the
                    best
                    AI engine (ChatGPT) with the convenient speech recognition and speech synthesis engines embedded in
                    modern phones, developed by Apple and Google. The WiseTalk assistant provides users with close to
                    real-time assistance, advice, and information on a wide range of topics. Sometimes the help,
                    support, and assistance are better and faster than that from a live human!
                    <Divider sx={{my: 2}}/>
                    The WiseTalk app makes all human wisdom, remembered and processed by AI, accessible for anyone.
                    <ul>
                        <li><b>W</b>ant to talk with a smart and supportive person?</li>
                        <li><b>I</b>nterested in personal tutor for any subject of your choice?</li>
                        <li><b>S</b>eeking to improve your skills in multiple foreign languages?</li>
                        <li><b>E</b>ager to retrieve forgotten information instantly?</li>
                        <li><b>T</b>rying to gain a deep understanding of complex concepts?</li>
                        <li><b>A</b>re you looking for guidance in the form of step-by-step instructions?</li>
                        <li><b>L</b>ooking for quick and accurate answers to your questions?</li>
                        <li><b>K</b>een to discover new topics and expand your knowledge?</li>
                    </ul>
                    <Divider sx={{my: 2}}/>
                    Just ask your WiseTalk Assistant, powered by ChatGPT. It can educate you on an endless number of
                    topics and answer almost any question!
                </Typography>

            </Box>
            {/* The Roles and Extensions */}
            <SpacingBox/>
            <Box sx={{bgcolor: 'primary.main', p: 4, borderRadius: 4, boxShadow: 4}} width="100%">
                <Typography variant="h6" gutterBottom color="white">
                    The Key Features, Roles, and Keyboard Extensions
                    <Divider sx={{my: 2}} color="white"/>
                    <Typography variant="h6" gutterBottom>
                        The <b>Proofreader Assistant Role</b> and its companion <b>Proofreading AI Keyboard</b> are
                        your personal editors, diligently reviewing your texts, emails, and notes, ensuring clarity,
                        and eliminating common pitfalls before you hit send. No more accidental blunders or awkward
                        autocorrects. No more need to copy text from one app to another for proofreading. With the
                        embedded Proofreading AI Keyboard, perfection is right at your fingertips, accessible in any
                        app you're using. Say hello to confidence in every word and sentence you write, ensuring
                        your message is always received as intended.
                    </Typography>
                    <Divider sx={{my: 2}} color="white"/>
                    <Typography variant="h6" gutterBottom>
                        The <b>Voice Translator</b> is reminiscent of the Babel Fish from "The Hitchhiker's Guide to
                        the Galaxy." Both serve as universal translators, enabling seamless communication between
                        individuals speaking different languages, thus breaking down language barriers and fostering
                        better understanding between people around the world.
                    </Typography>
                    <Divider sx={{my: 2}} color="white"/>
                    <Typography variant="h6" gutterBottom>
                        <b>WiseTalk</b> utilizes embedded in your
                        phone <b>speech-to-text</b> and <b>text-to-speech</b> engines to facilitate
                        intuitive, voice-driven conversations with the advanced <b>ChatGPT</b> AI language model.
                    </Typography>
                    <Divider sx={{my: 2}} color="white"/>
                    More predefined roles and other options are coming soon!
                </Typography>
            </Box>
            <SpacingBox/>
            {/* All features section */}
            <Box sx={{bgcolor: 'grayBackground.default', p: 4, borderRadius: 4, boxShadow: 4}} width="100%">
                <Typography variant="h6" gutterBottom>
                    List of Most Features
                    <Divider sx={{my: 2}}/>
                    <ul>
                        <li><b>Proofreader:</b> This advanced Role designed to enhance your writing. Dictate or type in
                            any language, and ChatGPT will provide corrections and improvements.
                        </li>
                        <li><b>Proofreading AI Keyboard:</b> Is an embedded companion keyboard extension that works in
                            Mail, Messenger, Messages, LinkedIn, X (Twitter), Gmail, etc.
                        </li>
                        <li><b>Voice Translator:</b> This advanced Role enables users to utilize the assistant for
                            real-time translation between languages, making it easy to communicate with people around
                            the world in their native languages.
                        </li>
                        <li><b>Voice-Based AI Interactions:</b> WiseTalk utilizes embedded speech-to-text and
                            text-to-speech engines to facilitate intuitive, voice-driven conversations with the advanced
                            ChatGPT AI language model.
                        </li>
                        <li><b>Real-Time Assistance:</b> Obtain real-time advice, information, and support across a wide
                            array of topics, making AI wisdom accessible wherever you are.
                        </li>
                        <li><b>Local Speech Processing:</b> WiseTalk prioritizes user privacy and convenience,
                            performing speech recognition and synthesis directly on your device—no need to send voice
                            data to the cloud.
                        </li>
                        <li><b>Reliable Connectivity:</b> Designed for optimal performance in areas with poor internet
                            connections, WiseTalk exchanges text-only data to ensure a smooth user experience.
                        </li>
                        <li><b>Multilingual Support:</b> WiseTalk embraces a global audience with its ability to
                            recognize and synthesize any language supported by your device.
                        </li>
                        <li><b>Customizable AI Roles:</b> Tailor the AI's response style to your needs, from simple
                            explanations by an elementary school teacher to in-depth analyses by a college professor.
                        </li>
                        <li><b>Conversation History:</b> Search for specific conversations with the AI Assistant by
                            searching for keywords and phrases. Pick up right where you left off: Select any
                            conversation from the history and continue chatting with the AI from that exact point.
                        </li>
                    </ul>
                </Typography>
            </Box>
            <SpacingBox/>
            {/*<Box sx={{bgcolor: 'grayBackground.default', p: 4, borderRadius: 4, boxShadow: 4}} width="100%">*/}
            {/*    <Typography variant="h6">*/}
            {/*        How does it work?*/}
            {/*        <Divider sx={{my: 2}}/>*/}
            {/*        <ul>*/}
            {/*            <li>Download the WiseTalk app from the Apple App Store or Google Play Store.</li>*/}
            {/*            <li>Launch the WiseTalk app.</li>*/}
            {/*            <li>Tap on the Mic button, and use your voice to start a conversation with the AI assistant.</li>*/}
            {/*            <li>Tap on the Arrow-Up (Send) button.</li>*/}
            {/*            <li>Wait for the response and listen.</li>*/}
            {/*        </ul>*/}
            {/*        Experience hands-free convenience as the app processes your request and reads aloud the text message response provided by the AI Assistant.*/}
            {/*    </Typography>*/}
            {/*</Box>*/}
            {/*<SpacingBox/>*/}
            {/*<Box sx={{bgcolor: 'primary.main', p: 4, borderRadius: 4, boxShadow: 4}} width="100%">*/}
            {/*    <Typography variant="h6" gutterBottom color="white">*/}
            {/*        How does 'Voice Translator' work?*/}
            {/*        <Divider sx={{my: 2}} color="white"/>*/}
            {/*        <ul>*/}
            {/*            <li>Tap on the Plus button and select 'Voice Translator'.</li>*/}
            {/*            <li>On the new screen, choose your native language by tapping on button A.</li>*/}
            {/*            <li>Select the foreign language by tapping on button B, on the opposite side of the screen.</li>*/}
            {/*            <li>Tap on the Mic button and use your voice to start a conversation.</li>*/}
            {/*            <li>Tap on the Arrow-Up (Send) button.</li>*/}
            {/*            <li>Wait for the translation and let the person you want to speak with listen to it.</li>*/}
            {/*            <li>Allow them to tap on the Mic button, on the opposite side of the screen, and speak.</li>*/}
            {/*            <li>Let them tap on the Arrow-Up (Send) button.</li>*/}
            {/*            <li>Wait for the translation, listen, and repeat.</li>*/}
            {/*        </ul>*/}
            {/*        The Voice Translator is reminiscent of the Babel Fish from "The Hitchhiker's Guide to the Galaxy." Both serve as universal translators, enabling seamless communication between individuals speaking different languages, thus breaking down language barriers and fostering better understanding between people around the world.*/}
            {/*    </Typography>*/}
            {/*</Box>*/}
            {/*<SpacingBox/>*/}
            <Box sx={{bgcolor: 'primary.main', p: 4, borderRadius: 4, boxShadow: 4}} width="100%">
                <Typography variant="h6" color="white">
                    Pricing and payment
                    <Divider sx={{my: 2}} color="white"/>
                    <ul>
                        <li><b>WiseTalk</b> and <b>Speech Translator</b> offer a trial of 10,000 tokens, which you can use to test out the app's
                            features. See the <a href="/support" rel="noopener noreferrer">FAQ section in
                                Support</a> for more information about tokens.
                        </li>
                        <li>After the trial, you can purchase additional tokens for a fee. The price varies
                            depending on the tier you choose: 40K for $1, 200K for $3, and 500K for $6.
                        </li>
                        <li>You can also subscribe to the service to double the number of tokens for the same price and
                            gain access to the history of your conversations and more advanced features.
                        </li>
                    </ul>
                    <Divider sx={{my: 2}} color="white"/>
                    Language Models (AI) do not think in words or images, but in tokens. Word tokens are the essential
                    units used to measure the amount of work an AI has to do to understand statements and generate
                    answers.
                    <Divider sx={{my: 2}} color="white"/>
                    <Typography sx={{fontStyle: "italic"}} variant="body1" gutterBottom color="white">
                        Please note that purchased tokens never expire. However, we reserve the right to change the
                        price or the number of tokens in a pack or the free trial at any time.
                    </Typography>
                </Typography>

            </Box>
            <SpacingBox/>
            <Box sx={{bgcolor: 'grayBackground.default', p: 4, borderRadius: 4, boxShadow: 4, width: '100%'}}>
                <Typography variant="h6" gutterBottom>
                    Download the <b>WiseTalk</b> app by scanning QR Code

                    <Box sx={{display: 'flex', justifyContent: 'center'}} width="100%">
                        <div style={{display: 'flex', float: 'left', width: '100%'}}>
                            <div className="first" style={{
                                flex: '40%',
                                maxWidth: '100%',
                                float: 'left',
                                padding: '5px',
                                textAlign: "center"
                            }}>
                                <img src="/qr_code_apple.png" width="100%" alt="itunes" style={{borderRadius: '5%'}}/>
                                <center>Apple App Store</center>
                            </div>
                            <div style={{
                                flex: '20%',
                                width: '256px',
                                float: 'left',
                                padding: '5px',
                                textAlign: "center"
                            }}></div>
                            <div style={{
                                flex: '40%',
                                maxWidth: '100%',
                                float: 'left',
                                padding: '5px',
                                textAlign: "center"
                            }}>
                                <img src="/qr_code_play.png" width="100%" alt="google play"
                                     style={{borderRadius: '5%'}}/>
                                <center>Google Play</center>
                            </div>
                        </div>
                    </Box>
                    <Divider sx={{my: 2}}/>
                    <Typography variant="h6">
                        Or click on one of the links
                        <Box sx={{display: 'flex', justifyContent: 'center'}}>
                            <ul>
                                <li className="first" style={{display: 'inline-block'}}>
                                    <a href="https://apps.apple.com/us/app/wisetalk-ai-powered-voice-chat/id6447212540"
                                       target="_blank"
                                       rel="noopener noreferrer">
                                        <img src="/app_store.png" alt="itunes"/>
                                    </a>
                                </li>
                                <li style={{display: 'inline-block'}}>
                                    <a href="https://play.google.com/store/apps/details?id=com.answersolutions.talkwise"
                                       target="_blank"
                                       rel="noopener noreferrer">
                                        <img src="/google_play.png" alt="google play"/>
                                    </a>
                                </li>
                            </ul>
                        </Box>
                    </Typography>
                </Typography>
            </Box>
            <SpacingBox/>
            <Box sx={{bgcolor: 'primary.main', p: 4, borderRadius: 4, boxShadow: 4}} width="100%">
                <Typography variant="h6" color="white">
                    Download the <b>Speech Translator</b> app by scanning QR Code
                    <Box sx={{display: 'flex', justifyContent: 'center'}} width="100%">
                        <div style={{display: 'flex', float: 'left', width: '100%'}}>
                            <div className="first" style={{
                                flex: '40%',
                                maxWidth: '40%',
                                float: 'left',
                                padding: '5px',
                                textAlign: "center"
                            }}>
                                <img src="/apple_voice_bridge_qr_code.png" width="100%" alt="itunes" style={{borderRadius: '5%'}}/>
                                <center>Apple App Store</center>
                            </div>
                            <div style={{
                                flex: '20%',
                                width: '256px',
                                float: 'left',
                                padding: '5px',
                                textAlign: "center"
                            }}></div>
                            <div style={{
                                flex: '40%',
                                maxWidth: '100%',
                                float: 'left',
                                padding: '5px',
                                textAlign: "center"
                            }}>
                                <img src="/google_voice_bridge_qr_code.png" width="100%" alt="google play"
                                     style={{borderRadius: '5%'}}/>
                                <center>Google Play</center>
                            </div>
                        </div>
                    </Box>
                    <Divider sx={{my: 2}} color="white"/>
                    <Typography variant="h6">
                        Or click on one of the links
                        <Box sx={{display: 'flex', justifyContent: 'center'}}>
                            <ul>
                                <li className="first" style={{display: 'inline-block'}}>
                                    <a href="https://apps.apple.com/us/app/speech-translator-be-heard/id6479205508"
                                       target="_blank"
                                       rel="noopener noreferrer">
                                        <img src="/app_store.png" alt="itunes"/>
                                    </a>
                                </li>
                                <li style={{display: 'inline-block'}}>
                                    <a href="https://play.google.com/store/apps/details?id=com.answersolutions.talkwise.voicebridge"
                                       target="_blank"
                                       rel="noopener noreferrer">
                                        <img src="/google_play.png" alt="google play"/>
                                    </a>
                                </li>
                            </ul>
                        </Box>
                    </Typography>
                </Typography>
            </Box>
        </Box>
    );
};

export default Home;