import React from 'react';
import {AppBar, Toolbar, Box, Button, styled} from '@mui/material';
import {Link, useLocation} from 'react-router-dom';

const StyledButton = styled(Button)(({theme, active}) => ({
    color: 'inherit',
    fontWeight: active ? 'bold' : 'normal',
    fontSize: active ? theme.typography.pxToRem(20) : theme.typography.pxToRem(16),
}));

const Navigation = () => {
    const location = useLocation();

    const isActive = (pathname) => {
        return location.pathname === pathname;
    };

    return (
        <AppBar position="static">
            <Box sx={{flexGrow: 1, display: 'flex', justifyContent: 'center'}}>
                <Toolbar width="100%">
                    <StyledButton component={Link} to="/" active={isActive('/')}>
                        Home
                    </StyledButton>
                    {/*<StyledButton*/}
                    {/*    component={Link}*/}
                    {/*    to="/pricing"*/}
                    {/*    active={isActive('/pricing')}*/}
                    {/*>*/}
                    {/*    Pricing*/}
                    {/*</StyledButton>*/}
                    <StyledButton
                        component={Link}
                        to="/tutorial"
                        active={isActive('/tutorial')}
                    >
                        Tutorial
                    </StyledButton>
                    <StyledButton
                        component={Link}
                        to="/privacy-policy"
                        active={isActive('/privacy-policy')}
                    >
                        Policy
                    </StyledButton>
                    <StyledButton
                        component={Link}
                        to="/user-agreement"
                        active={isActive('/user-agreement')}
                    >
                        Agreement
                    </StyledButton>
                    <StyledButton
                        component={Link}
                        to="/support"
                        active={isActive('/support')}
                    >
                        Support
                    </StyledButton>
                </Toolbar>
            </Box>
        </AppBar>
    );
};

export default Navigation;
